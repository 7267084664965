import type { FC } from 'react'

import type { ViewPropsType } from '@components/header/RegionalMenu/components/MenuContainer/MenuContainer'

import { DefaultViewWrapper, GlobeIconStyled } from './styles'

export const DefaultView: FC<ViewPropsType> = ({ onClick }) => {
  return (
    <DefaultViewWrapper onClick={onClick}>
      <GlobeIconStyled />
    </DefaultViewWrapper>
  )
}
