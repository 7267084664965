import styled from 'styled-components'

import GlobeIcon from '@iconscout/unicons/svg/line/globe.svg'
import { flexCenter } from '@styles/basic/flex'

export const DefaultViewWrapper = styled.button`
  ${flexCenter};
`

export const GlobeIconStyled = styled(GlobeIcon)`
  display: flex;
  align-items: center;
  cursor: pointer;
  fill: ${p => p.theme.colors.icon};
`
